import {
  NormalizedCategory,
  resolveId,
} from '@wix/communities-blog-client-common';

type HeaderLink = {
  key: string;
  path: string;
  text: string;
  postAmount: number;
};

type Params = {
  categories: NormalizedCategory[];
  feedTitle: string;
  categoryPath: string;
  totalPublicationsCount?: number;
  withFeedLink?: boolean;
};

export const getHeaderLinks = ({
  categories,
  feedTitle,
  categoryPath,
  totalPublicationsCount = 0,
  withFeedLink = true,
}: Params): HeaderLink[] =>
  categories.reduce(
    (acc, category) => {
      acc.push({
        key: resolveId(category),
        path: `/${categoryPath}/${category.slug}`,
        text: category.menuLabel,
        postAmount: category.postAmount,
      });
      return acc;
    },
    withFeedLink
      ? [
          {
            key: 'latest_posts',
            path: '',
            text: feedTitle,
            postAmount: totalPublicationsCount,
          },
        ]
      : [],
  );
