import './styles.scss';

import React, { useCallback, useEffect, useRef } from 'react';
import {
  createPageUrl,
  feedWidgetStyleParams,
  isLayoutProGallery,
  SECTION_HOMEPAGE,
} from '@wix/communities-blog-client-common';
import AnimatedLoader from '../../../common/components/animated-loader';
import AppLoaded from '../../../common/components/app-loaded';
import { useComponents } from '../../../common/components/components-provider/use-components';
import Page from '../../../common/components/page';
import { useSelector } from '../../../common/components/runtime-context';

import NoPostsFeed from '../../../common/containers/no-posts-feed';
import Pagination from '../../../common/containers/pagination';
import useLayoutProps from '../../../common/hooks/use-layout-props';
import usePermissions from '../../../common/hooks/use-permissions';
import { getAppSettingsValue } from '../../../common/selectors/app-settings-base-selectors';
import {
  getCanSeeMoreButton,
  getIsFeaturedPostBannerEnabled,
} from '../../../common/selectors/app-settings-selectors';
import { isCreatePostButtonVisible } from '../../../common/selectors/blog-menu-button-selectors';
import {
  isExcludePostContentSupported,
  isPostContentRequired,
} from '../../../common/selectors/layout-selectors';
import {
  getFeedPosts,
  getFeedPostsByPage,
} from '../../../common/selectors/post-selectors';
import { handleNavigation } from '../../../common/services/navigation';
import {
  getIsMobile,
  isEditor as getIsEditor,
  isSite as getIsSite,
} from '../../../common/store/basic-params/basic-params-selectors';
import { getIsFeedPostsLoaded } from '../../../common/store/is-loaded/is-loaded-selectors';
import { getIsFeedPostsLoading } from '../../../common/store/is-loading/is-loading-selectors';
import { getSectionUrl } from '../../../common/store/topology/topology-selectors';
import PostList from '../../components/post-list';
import { RouteHomeParams } from '../../constants/routes';
import usePaginationSettings from '../../hoc/use-pagination-settings';
import FeaturedPostBanner from '../featured-post-banner';

type Props = {
  params: RouteHomeParams;
  isBlocked?: boolean;
};

export const FeedPage = (props: Props) => {
  const { PostListProGallery } = useComponents();
  const { canSee } = usePermissions();
  const layoutProps = useLayoutProps();

  const pagination = usePaginationSettings(SECTION_HOMEPAGE, props.params.page);
  const prevPaginationRef = useRef(pagination);

  const feedPage = useSelector((state, host, actions) => {
    const currentPagePosts = getFeedPostsByPage(state, pagination.page);
    const sectionUrl = getSectionUrl(state);
    const showCreatePostAction =
      getIsMobile(state) && isCreatePostButtonVisible(state, canSee);
    const {
      feedSliderShowArrows,
      feedSliderArrowsSize,
      feedSliderAutoSlide,
      feedSliderPauseTime,
      feedSliderArrowsPosition,
      feedSliderArrowsColor,
      feedSliderLoop,
    } = feedWidgetStyleParams;

    return {
      posts: getFeedPosts(state),
      currentPagePosts,
      newPostId: state.newPostMessage,
      isLoading: getIsFeedPostsLoading(state),
      isLoaded: getIsFeedPostsLoaded(state),
      showCreatePostAction,
      excludeContent: isExcludePostContentSupported(state),
      isEditor: getIsEditor(state),
      isSite: getIsSite(state),
      fetchFeedPosts: ({ page }: { page: number }) =>
        actions.fetchFeedPostsWithAdapterPromisified({
          page,
          includeContent: isPostContentRequired(state),
        }),

      sectionUrl,
      isBannerEnabled: getIsFeaturedPostBannerEnabled(state),
      canSeeMoreButton: getCanSeeMoreButton(state, canSee),
      layoutOptions: {
        showArrows: getAppSettingsValue({
          state,
          key: `style.booleans.${feedSliderShowArrows.key}`,
          fallback: feedSliderShowArrows.defaultValue,
        }),
        arrowsSize: getAppSettingsValue({
          state,
          key: `style.numbers.${feedSliderArrowsSize.key}`,
          fallback: feedSliderArrowsSize.defaultValue,
        }),
        autoSlide: getAppSettingsValue({
          state,
          key: `style.booleans.${feedSliderAutoSlide.key}`,
          fallback: feedSliderAutoSlide.defaultValue,
        }),
        pauseTime: getAppSettingsValue({
          state,
          key: `style.numbers.${feedSliderPauseTime.key}`,
          fallback: feedSliderPauseTime.defaultValue,
        }),
        arrowsPosition: getAppSettingsValue({
          state,
          key: `style.numbers.${feedSliderArrowsPosition.key}`,
          fallback: feedSliderArrowsPosition.defaultValue,
        }),
        arrowsColor: getAppSettingsValue({
          state,
          key: `style.colors.${feedSliderArrowsColor.key}`,
          fallback: feedSliderArrowsColor.defaultValue,
        }),
        loop: getAppSettingsValue({
          state,
          key: `style.booleans.${feedSliderLoop.key}`,
          fallback: feedSliderLoop.defaultValue,
        }),
      },
    };
  });
  const prevFeedPageRef = useRef(feedPage);

  const fetchFeedPosts = useCallback(
    (page = pagination.page) => {
      if (props.isBlocked) {
        return;
      }
      return feedPage.fetchFeedPosts({
        page,
        excludeContent: feedPage.excludeContent,
      } as any);
    },
    [feedPage, pagination.page, props.isBlocked],
  );

  const loadMore = (page: number) => fetchFeedPosts(page);
  const loadRest = () => fetchFeedPosts(pagination.page);

  useEffect(() => {
    if (
      prevPaginationRef.current.pageSize !== pagination.pageSize ||
      prevFeedPageRef.current.excludeContent !== feedPage.excludeContent
    ) {
      fetchFeedPosts();
    }
  }, [feedPage.excludeContent, fetchFeedPosts, pagination.pageSize]);

  const renderPosts = () => {
    const { entityCount, page, showPagination, pageSize } = pagination;
    const {
      currentPagePosts,
      posts,
      isLoading,
      isLoaded,
      showCreatePostAction,
      isEditor,
      canSeeMoreButton,
    } = feedPage;

    const hasPosts = posts.length;

    if (isLoaded && !hasPosts) {
      return <NoPostsFeed />;
    }

    if (layoutProps.layoutType && isLayoutProGallery(layoutProps.layoutType)) {
      return (
        <PostListProGallery
          pageStart={page + 1}
          entityCount={pagination.entityCount}
          loadMore={pagination.showPagination ? undefined : loadMore}
          allPosts={showPagination || isEditor ? currentPagePosts : posts}
          currentPagePosts={currentPagePosts}
          isLoading={isLoading}
          showCreatePostAction={showCreatePostAction}
          canSeeMoreButton={canSeeMoreButton}
          layoutOptions={feedPage.layoutOptions}
          section={SECTION_HOMEPAGE}
        />
      );
    }

    return (
      <PostList
        pageStart={page + 1}
        entityCount={entityCount}
        page={page}
        pageSize={pageSize}
        loadRest={showPagination ? loadRest : undefined}
        loadMore={showPagination ? undefined : loadMore}
        allPosts={showPagination || isEditor ? currentPagePosts : posts}
        currentPagePosts={currentPagePosts}
        isLoading={isLoading}
        showCreatePostAction={showCreatePostAction}
      />
    );
  };

  const pageUrlBuilder = (page: number) =>
    createPageUrl(page, feedPage.sectionUrl);

  const renderPagination = () => {
    return (
      <div
        data-hook="feed-pagination-container"
        style={{ display: pagination.showPagination ? 'block' : 'none' }}
      >
        <Pagination
          page={pagination.page}
          pageSize={pagination.pageSize}
          entityCount={pagination.entityCount}
          createPageUrl={pageUrlBuilder}
          handleNavigation={handleNavigation('/', feedPage.isSite)}
          showPagination={pagination.showPagination}
        />
      </div>
    );
  };

  return (
    <Page noSpacing>
      {feedPage.isBannerEnabled && <FeaturedPostBanner />}
      <AnimatedLoader
        isLoading={!feedPage.isLoaded}
        isAnimated={!feedPage.isLoaded}
      >
        {renderPosts()}
      </AnimatedLoader>
      {renderPagination()}
      {feedPage.isLoaded && <AppLoaded />}
    </Page>
  );
};

export default FeedPage;
