import React from 'react';
import classNames from 'classnames';
import { useTranslation } from '@wix/yoshi-flow-editor';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import withFontClassName from '../../hoc/with-font-class-name';
import LoginButton from '../login-button';
import styles from './login-screen.scss';

const rootClasses = [
  'login-screen',
  'blog-card-background-color',
  'blog-card-border-color',
  'blog-text-color',
];

type LoginScreenProps = {
  borderWidth: number;
  titleFontClassName: string;
  contentFontClassName: string;
};

const LoginScreen: React.FC<LoginScreenProps> = ({
  children,
  borderWidth,
  titleFontClassName,
  contentFontClassName,
  ...props
}) => {
  const rootClassName = classNames(
    styles.page,
    ...rootClasses,
    contentFontClassName,
  );

  const { t } = useTranslation();

  return (
    <div className={rootClassName} style={{ borderWidth }}>
      <div className={styles.closeButton}>{children}</div>
      <div className={titleFontClassName}>
        <h1 className={classNames(styles.title, 'login-screen__title')}>
          {t('login-screen.login-required')}
        </h1>
      </div>
      <p
        className={classNames(styles.description, 'login-screen__description')}
      >
        {t('login-screen.login-description')}
      </p>
      <div
        className={classNames(
          styles.loginButtonContainer,
          'login-screen__login-button-container',
        )}
      >
        <LoginButton autoLogin {...props} />
      </div>
    </div>
  );
};

export default withCardBorderWidth(withFontClassName(LoginScreen));
