import React, { Component } from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import { EXPERIMENT_DEPRECATE_NOTIFICATIONS_BELL } from '@wix/communities-blog-experiments';
import CreatePostButton from '../../../feed-page/components/create-post-button';
import { importPrivateProfileActions } from '../../containers/private-profile-actions';
import preventClickInDemoMode from '../../hoc/prevent-click-in-demo-mode';
import withAuth from '../../hoc/with-auth';
import withExperiment from '../../hoc/with-experiment';
import withPermissions from '../../hoc/with-permissions';
import withTranslate from '../../hoc/with-translate';
import { getRoute } from '../../router/router-selectors';
import { getIsBlogMenuLoginButtonEnabled } from '../../selectors/app-settings-selectors';
import { isCreatePostButtonVisible } from '../../selectors/blog-menu-button-selectors';
import { isInPostEditor } from '../../services/detect-route';
import { getIsMemberAreaInstalled } from '../../store/communities-context/communities-context-selectors';
import Button from '../button';
import DropdownButton from '../dropdown-button';
import NotificationsLink from '../link/notifications-link';
import NotificationButton from '../notification-button';
import { connect } from '../runtime-context';
import UserAvatarImage from '../user-avatar-image';
import styles from './header-widget.scss';

const ProtectedButton = preventClickInDemoMode(Button);

export class HeaderWidget extends Component {
  renderCreatePostButton = () => (
    <div className={styles.createButtonContainer}>
      <CreatePostButton
        hidePostfix={true}
        className={styles.createPostButton}
        wrapperClassName={styles.createPostButtonWrapper}
      />
    </div>
  );

  renderLogin = () => (
    <div className={styles.container}>
      {this.renderSearchInput()}
      {this.renderLoginButton()}
    </div>
  );

  renderMainWidget = () => (
    <div className={styles.container}>
      {this.renderSearchInput()}
      {this.renderNotifications()}
      {this.renderProfile()}
      {this.props.showCreatePostButton && this.renderCreatePostButton()}
    </div>
  );

  renderLoginButton = () => {
    const { requestLogin, isBlogMenuLoginButtonEnabled } = this.props;

    if (!isBlogMenuLoginButtonEnabled) {
      return null;
    }

    return (
      <ProtectedButton
        className={styles.loginButton}
        isSecondary
        onClick={() => requestLogin()}
        data-hook="login-button"
      >
        {this.props.t('header.sign-up-button')}
      </ProtectedButton>
    );
  };

  renderNotifications = () => {
    if (
      this.props.isNotificationsBellDeprecated ||
      !(
        this.props.isBlogMenuLoginButtonEnabled && this.props.isMemberAreaActive
      )
    ) {
      return null;
    }

    return (
      <NotificationButton
        className={styles.notificationButton}
        component={NotificationsLink}
        iconClassName="blog-header-widget-icon-fill"
        bubbleClassName={classNames(
          'blog-header-border-color',
          'blog-header-notification-bubble-background-color',
          'blog-header-notification-bubble-text-color',
          styles.notificationBubble,
        )}
        data-hook="notification-button"
      />
    );
  };

  renderProfile = () => {
    const { currentUser, isBlogMenuLoginButtonEnabled } = this.props;

    if (!isBlogMenuLoginButtonEnabled) {
      return null;
    }

    const icon = (
      <button
        className={classNames(styles.avatarImage)}
        aria-label={this.props.t('header.profile')}
        data-hook="avatar-image"
      >
        <UserAvatarImage type="widget" user={currentUser} />
      </button>
    );
    return (
      <div className={styles.profile}>
        <DropdownButton
          actionsContainerClassName={styles.profileActions}
          position={DropdownButton.POSITION_RIGHT}
          icon={icon}
        >
          {async () => {
            const PrivateProfileActions = await importPrivateProfileActions();
            return <PrivateProfileActions user={currentUser} />;
          }}
        </DropdownButton>
      </div>
    );
  };

  renderSearchInput = () => (
    <div className={styles.searchInput} data-hook="search-input">
      {this.props.searchInput}
    </div>
  );

  render() {
    const { isAuthenticated, showLogin, viewMode } = this.props;

    const isEditor = viewMode && viewMode === 'editor';

    // For app settings toggling
    if (isEditor && showLogin) {
      return this.renderLogin();
    }

    if (!isAuthenticated) {
      return this.renderLogin();
    } else {
      return this.renderMainWidget();
    }
  }
}

HeaderWidget.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  showLogin: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool.isRequired,
  isBlogMenuLoginButtonEnabled: PropTypes.bool,
  requestLogin: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  viewMode: PropTypes.string.isRequired,
  showHeaderLogin: PropTypes.func.isRequired,
  searchInput: PropTypes.node,
  currentUser: PropTypes.object,
  isMemberAreaActive: PropTypes.bool,
  showCreatePostButton: PropTypes.bool,
  isNotificationsBellDeprecated: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  isMemberAreaActive: getIsMemberAreaInstalled(state),
  isBlogMenuLoginButtonEnabled: getIsBlogMenuLoginButtonEnabled(state),
  showLogin: state.headerWidget.showLogin,
  viewMode: state.viewMode,
  requestLogin: actions.requestLoginPromisified,
  showHeaderLogin: actions.showHeaderLogin,
  showCreatePostButton:
    !isInPostEditor(getRoute(state)) &&
    isCreatePostButtonVisible(state, ownProps.canSee),
});

export default flowRight(
  withAuth,
  withTranslate,
  withPermissions,
  withExperiment({
    isNotificationsBellDeprecated: EXPERIMENT_DEPRECATE_NOTIFICATIONS_BELL,
  }),
  connect(mapRuntimeToProps),
)(HeaderWidget);
