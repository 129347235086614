import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../../common/components/button';
import { PlusIcon } from '../../../common/components/icons/plus-icon';
import CreatePostHandler from '../../../common/containers/create-post-handler';
import ensureAuth from '../../../common/hoc/ensure-auth';
import withTranslate from '../../../common/hoc/with-translate';
import styles from './create-post-button.scss';

const ButtonSecure = ensureAuth(Button);
const buttonType = [
  Button.TYPE_FULL_WIDTH_MOBILE,
  Button.TYPE_SMALL_SPACINGS_MOBILE,
  Button.TYPE_LARGE,
];

export const CreatePostButton = ({
  categorySlug,
  t,
  hidePostfix,
  className,
  wrapperClassName,
}) => (
  <CreatePostHandler categorySlug={categorySlug}>
    <ButtonSecure
      type={buttonType}
      className={classNames([styles.wrapper, wrapperClassName])}
      data-hook="create-post"
    >
      <div className={styles.container}>
        <span className={classNames(['create-post-button-label', className])}>
          {t('create-post-button.label')}
        </span>
        {!hidePostfix && <PlusIcon className="blog-button-primary-icon-fill" />}
      </div>
    </ButtonSecure>
  </CreatePostHandler>
);

CreatePostButton.propTypes = {
  categorySlug: PropTypes.string,
  t: PropTypes.func.isRequired,
};

export default withTranslate(CreatePostButton);
