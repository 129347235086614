import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';
import { omit } from 'lodash';

import styles from './page.scss';

export type PageProps = {
  noSpacing?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const Page: React.FC<PageProps> = ({
  className,
  noSpacing,
  children,
  ...rest
}) => {
  const containerProps = omit(
    rest,
    'isDesktop',
    'isMobile',
    'section',
    'layoutType',
    'layoutName',
    'dispatch',
  );
  return (
    <div
      className={classNames(
        styles.container,
        { [styles.noSpacing]: noSpacing },
        className,
      )}
      {...containerProps}
    >
      {children}
    </div>
  );
};

export default Page;
